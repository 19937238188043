import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
//import {navigate} from "@reach/router";
//import {checkLanguage, paths} from "../../utils";

const NeedHelpButton = ({children,location,intl,type,fromhome}) => {
    return (
        <Button
                onClick={()=>{(fromhome?window.dataLayer.push({'event': 'NeedHelpHome'}):window.dataLayer.push({'event': 'NeedHelp'}))}}
                rounded
                btnType={type}
                fullwidth={true}
        >
            {children}
        </Button>
    )
}

export default injectIntl(NeedHelpButton)
