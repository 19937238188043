import React, { useState } from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import styled from "styled-components"
import Img from "gatsby-image";
import BuyButton from "../commonV3/BuyButton";
import IUnion from "../../images/svgs/checkblack.svg"
import ITrial from "../../images/svgs/Trial.svg"
import IShipping from "../../images/svgs/Envios.svg"
import IStar from "../../images/svgs/Star.svg"
import IArrow from "../../images/svgs/arrow_up.svg"
//import { BottomSheet } from "react-spring-bottom-sheet"

const BasicPlanData = {
  recommended: false,
  title: "planBasicTitle",
  price: "planBasicPrice",
  description: "planBasicDescription",
  includesPlan: [],
  hint: "planBasicHint",
  button: {
    price: process.env.GATSBY_MONTHLY,
    size: process.env.GATSBY_ADULTS
  },
  includes: [],
  conditions: false
}

const MonthlyPlanData = {
  recommended: true,
  title: "planMontlyTitle",
  price: "planMontlyPrice",
  discount: "planMontlyDiscount",
  description: "planMontlyDescription",
  includesPlan: [
    "planIncludeNoPreviousPayment",
    "planIncludeWatchIncluded"
  ],
  hint: "planMontlyHint",
  button: {
    price: process.env.GATSBY_SUPERMONTHLY,
    size: process.env.GATSBY_FREE_WATCH
  },
  includes: [
    "planIncludeAllInclusive"
  ],
  conditions: true
}

const YearlyPlanData = {
  recommended: false,
  title: "planAnnualTitle",
  price: "planAnnualPrice",
  discount: "planAnnualDiscount",
  description: "planAnnualDescription",
  includesPlan: [],
  hint: "planAnnualHint",
  button: {
    price: process.env.GATSBY_ANNUAL,
    size: process.env.GATSBY_FREE_WATCH
  },
  includes: [],
  conditions: false
}

const PlanDataDesktop = [BasicPlanData, MonthlyPlanData, YearlyPlanData]
const PlanDataMobile = [MonthlyPlanData, YearlyPlanData, BasicPlanData]

const Plan = ({ intl, data, buyInfo, isSelected, onSelect, onSeeConditions }) => {
  const [showFeatures, setShowFeatures] = useState(false)
  const [showCond, setShowCond] = useState(false)

  return (
    <PlanWrapper
      highlighted={isSelected}
      onClick={() => onSelect(data.title)}
    >
      <Label hidden={!data.recommended}><IStar />{intl.formatMessage({ id: "planRecommended" })}</Label>
      <div className="Title">{intl.formatMessage({ id: data.title })}</div>
      <div className="Price">
        <FormattedMessage
          id="planPrice"
          defaultMessage={intl.formatMessage({ id: data.price })}
          values={{
            span: chunks => <span>{chunks}</span>,
            subspan: chunks => <subspan>{chunks}</subspan>,
            br: <br />
          }}
        />
      </div>
      <div className={"Discount" + (data.discount ? "" : "BgWhite")}> {data.discount ? intl.formatMessage({ id: data.discount }) : ""}</div>
      <div className="Description">
        <FormattedMessage
          id="planDesc"
          defaultMessage={intl.formatMessage({ id: data.description })}
          values={{
            strong: chunks => <strong>{chunks}</strong>,
            br: <br />
          }}
        />
        <IncludesDetailWrapper>
          {data.includesPlan.map(value => (
            <Includes key={value}><IUnion />{intl.formatMessage({ id: value })}</Includes>
          ))}
        </IncludesDetailWrapper>
      </div>
      <div className="Hint">{intl.formatMessage({ id: data.hint })}</div>
      <BuyButton
        id="gta_click_pay"
        userId={buyInfo.userId}
        promotion_code={buyInfo.promotion_code}
        purchase_method={buyInfo.purchase_method}
        type={isSelected ? "primary" : "secondary"}
        price={data.button.price}
        size={data.button.size}
        intl={intl}
        location={buyInfo.location}>
        <ButtonText>{intl.formatMessage({ id: "continueButton" })}</ButtonText>
      </BuyButton>
      <FeatureWrapper>
        <Feature>
          <IShipping />
          <div className="FeatureTitle">{intl.formatMessage({ id: "planFreeShippingTitle" })}</div>
          <div className="FeatureDescription">
            <FormattedMessage
              id="freeShipping"
              defaultMessage={intl.formatMessage({ id: "planFreeShippingDescription" })}
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                br: <br />
              }}
            />
          </div>
        </Feature>
        <Feature divider={true}>
          <ITrial />
          <div className="FeatureTitle">{intl.formatMessage({ id: "planTrialTitle" })}</div>
          <div className="FeatureDescription">
            <FormattedMessage
              id="trial"
              defaultMessage={intl.formatMessage({ id: "planTrialDescription" })}
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                br: <br />
              }}
            />
          </div>
        </Feature>
      </FeatureWrapper>
      <SeeFeatures expanded={showFeatures} onClick={() => setShowFeatures(!showFeatures)}>
        {intl.formatMessage({ id: "moreAdventatges" })}<IArrow />
      </SeeFeatures>
      <IncludesDetailWrapper hidden={!showFeatures}>
        {
          data.includes.map(text => (
            <Includes key={text}><IUnion /><FormattedMessage
              id="include"
              defaultMessage={intl.formatMessage({ id: text })}
              values={{
                strong: chunks => <strong>{chunks}</strong>,
                br: <br />
              }}
            /></Includes>
          ))
        }
        <Includes><IUnion />{intl.formatMessage({ id: "planInclude24-7" })}</Includes>
        <Includes><IUnion />{intl.formatMessage({ id: "planIncludeFalls" })}</Includes>
        <Includes><IUnion />{intl.formatMessage({ id: "planIncludeLocation" })}</Includes>
        <Includes><IUnion />{intl.formatMessage({ id: "planIncludeSIM" })}</Includes>
        <Includes><IUnion />{intl.formatMessage({ id: "planIncludeVitalSigns" })}</Includes>
        <Includes><IUnion />{intl.formatMessage({ id: "planIncludeSupport" })}</Includes>
      </IncludesDetailWrapper>
      <SeeConditions hidden={!data.conditions} expanded={showCond} onClick={() => setShowCond(!showCond)}>
        {intl.formatMessage({ id: "seeConditions" })}
        <IArrow />
      </SeeConditions>
      <SeeConditionsMobile hidden={!data.conditions} onClick={() => onSeeConditions()}>
        {intl.formatMessage({ id: "seeConditions" })}
      </SeeConditionsMobile>
      <PlanFooter hidden={!showCond}>
        <div className="FooterTitle">{intl.formatMessage({ id: "planFooterTitle" })}</div>
        <div className="FooterDescription">· {intl.formatMessage({ id: "planFooterDesc1" })}</div>
      </PlanFooter>
    </PlanWrapper>
  )
}

const PlansBuyPage = ({ intl, location, userId, promotion_code, purchase_method, onCLickSeeConditions }) => {
  let buyInfo = {
    location: location,
    userId: userId,
    promotion_code: promotion_code,
    purchase_method: purchase_method
  }

  const recommendedPlan = PlanDataDesktop.find(plan => plan.recommended)
  const [selectedPlan, setSelectedPlan] = useState(recommendedPlan ? recommendedPlan.title : null)

  return (
    <div>
      <PlansBuyPageWrapper>
        {PlanDataDesktop.map(value => (
          <Plan
            key={value.title}
            intl={intl}
            data={value}
            buyInfo={buyInfo}
            isSelected={selectedPlan === value.title}
            onSelect={setSelectedPlan}
            onSeeConditions={onCLickSeeConditions}
          />
        ))}
      </PlansBuyPageWrapper>
      <PlansBuyPageWrapperMobile>
        {PlanDataMobile.map(value => (
          <Plan
            key={value.title}
            intl={intl}
            data={value}
            buyInfo={buyInfo}
            isSelected={selectedPlan === value.title}
            onSelect={setSelectedPlan}
            onSeeConditions={onCLickSeeConditions}
          />
        ))}
      </PlansBuyPageWrapperMobile>
    </div>
  )
}

const PlanWrapper = styled.div`
    border-radius: 20px;
    background-color: ${props => (props.color ? props.color : props.theme.color.neutrals.neutral0)};
    padding: ${props=> (props.highlighted? "22px":"26px")};
    max-width: 330px;
    border: ${props=> (props.highlighted? "solid "+props.theme.color.purple+" 4px" : "none")};
    
    .Title {
        margin-top: 24px;
        font-family: ${({theme}) => theme.font.bold};
        ${({theme}) => theme.font_size.medium};
    }
    .Price {
        margin-top: 24px;
        font-family: ${({theme}) => theme.font.bold};
        ${({theme}) => theme.font_size.medium};
        span {
            ${({ theme }) => theme.font_size.xlarge};
        }
        subspan {
            ${({ theme }) => theme.font_size.larger};
        }
    }
    .Discount {
        background-color: ${({ theme }) => theme.color.greens.green20};
        border-radius: 4px;
        color: #03402D;
        margin-top: 12px;
        padding: 4px 8px 4px 8px;
        width: fit-content;
        min-height: 24px;
        font-family: ${({theme}) => theme.font.medium500};
        ${({theme}) => theme.font_size.xsmall};
    }
    .DiscountBgWhite {
        margin-top: 12px;
        background-color: ${({ theme }) => theme.color.white};
        @media (min-width: ${props => props.theme.screen.md}) {
            min-height: 24px;
        }
    }
    .Description {
        margin-top: 24px;
        font-family: ${({theme}) => theme.font.medium};
        ${({theme}) => theme.font_size.small};
        
        @media (min-width: ${props => props.theme.screen.md}) {
            min-height: 150px;
        }
    }
    .Hint {
        margin: 24px 0 16px 0;
        font-family: ${({theme}) => theme.font.medium};
        ${({theme}) => theme.font_size.small};
        color: ${({ theme }) => theme.color.grey.grey04};
    }
`

const Label = styled.div`
    display: flex;
    visibility: ${props => props.hidden ? "hidden" : "visible"};
    flex-direction: row;
    gap: 4px;
    border-radius: 0 20px 0 20px;
    background-color: ${({ theme }) => theme.color.purple};
    color: white;
    padding: 8px;
    float: right;
    margin: -24px -24px 0 0;
    width: fit-content;
    align-items: end;
    font-family: ${({theme}) => theme.font.medium};
    ${({theme}) => theme.font_size.xsmall};
`

const FeatureWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 32px;
`

const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1 0 0;
    border-left: ${props => props.divider ? "1px solid #E5E5E5" : "none"};
    svg {
        width: 20px;
        height: 20px;
    }
    .FeatureTitle {
        margin-top: 8px;
        font-family: ${({theme}) => theme.font.bold};
        ${({theme}) => theme.font_size.small};
    }
    .FeatureDescription {
        margin-top: 4px;
        min-height: unset;
        font-family: ${({theme}) => theme.font.small};
        ${({theme}) => theme.font_size.xsmall};
    }
`

const SeeFeatures = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 24px;
    gap: 8px;
    cursor: pointer;
    
    ${({ theme }) => theme.font_size.regular};
    font-family: ${({ theme }) => theme.font.small};
    color: ${({ theme }) => theme.color.purple};
    
    svg {
        transform: ${props => props.expanded ? "rotate(0deg)" : "rotate(180deg)"};
    }
    
    svg path {
        fill: ${({ theme }) => theme.color.purple};
    }

    @media (min-width: ${props => props.theme.screen.md}) {
        display: none;
    }
`

const IncludesDetailWrapper = styled.div`
    display: ${props => props.hidden ? "none" : "flex"};
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-top: 16px;
    gap: 8px;
    width: 100%;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    
    svg {
        min-height: 16px;
        min-width:16px;
    }

    @media (min-width: ${props => props.theme.screen.md}) {
        display: flex;
    }
`

const Includes = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  padding-top: 8px;
  gap: 8px;

  width: 100%;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
  ${({ theme }) => theme.font_size.small};
  font-family: ${({ theme }) => theme.font.small};
`
const SeeConditions = styled.div`
    display: ${props => props.hidden ? "none" : "flex"};
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 24px;
    gap: 8px;
    cursor: pointer;
    
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.small};
    color: ${({ theme }) => theme.color.grey.grey04};
    svg {
        transform: ${props => props.expanded ? "rotate(0deg)" : "rotate(180deg)"};
    }
    @media (max-width: ${props => props.theme.screen.md}) {
        display: none;
    }

`
const SeeConditionsMobile = styled.div`
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 24px;
    gap: 8px;
    cursor: pointer;
    display: none;
    
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.small};
    color: ${({ theme }) => theme.color.grey.grey04};
    text-decoration: underline;
    @media (max-width: ${props => props.theme.screen.md}) {
        display: ${props => props.hidden ? "none" : "flex"};
    }

`

const PlanFooter = styled.div`
    display: ${props => props.hidden ? "none" : "flex"};
    flex-direction: column;
    .FooterTitle {
        margin-top: 16px;
        ${({ theme }) => theme.font_size.regular};
    }
    .FooterDescription {
        margin: 12px 0 0 4px;
        ${({ theme }) => theme.font_size.small};
    }
    .FooterHint {
        margin-top: 16px;
        ${({ theme }) => theme.font_size.small};
        color: ${({ theme }) => theme.color.grey.grey04};
    }
`

const PlansBuyPageWrapper = styled.div`
  display: none;
    
  @media (min-width: ${props => props.theme.screen.md}) {  
      display: flex;
      flex-direction: row;  
      justify-content: center;  
      gap: 24px;  
      margin: 16px 64px 32px 64px;
  }
`
const PlansBuyPageWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 16px;
    
  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const StyledIcon = styled(Img)`
  position: absolute;
  width: 21px;
`
const ButtonText = styled.div`
  margin: 0;
  text-align: center;
  ${({theme}) => theme.font_size.regular};
  font-family: ${({theme}) => theme.font.bold};
`
export default injectIntl(PlansBuyPage)
