import React from "react"
import {injectIntl} from "gatsby-plugin-intl"
import Button from "../common/button";
import SVGPhone from "../../images/svgs/llamar.svg";
import campaigns from "../../../static/ddi.json"

const MoreInfoButton = ({children,location,intl,type}) => {
  let salesPhone = process.env.GATSBY_SALES_PHONE;
  function getPhoneByCampaignID(campaignID) {
    const campaign = campaigns.find(item => item.campaignID === campaignID);
    return campaign ? campaign.phone : salesPhone;
  }
 // const location = useLocation();
  const params = new URLSearchParams(location.search);
  const campaignID = params.get("campaign");

  const phone = campaignID ? getPhoneByCampaignID(campaignID) : salesPhone;
  const phoneText = phone.replace(/(\d{3})/g, "$1 ").trim();
    return (
        <Button id="gta_click_call"
                onClick={()=>{
                    window.dataLayer.push({'event': 'buyClickCall'});
                    window.open(`tel:${phone}`);
                    }}
                rounded
                btnType={type}
                fullwidth={true}
        >
            {children}
            <SVGPhone/>
        </Button>
    )
}

export default injectIntl(MoreInfoButton)
