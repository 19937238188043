import React, {useCallback, useEffect, useState} from "react"
import {injectIntl} from "gatsby-plugin-intl"
import styled from "styled-components"

import {graphql, useStaticQuery} from "gatsby";
import {StringParam, withQueryParams} from "use-query-params";
import Img from "gatsby-image";
import MoreInfoButton from "../commonV3/MoreInfoButton";
import BuyButton from "../commonV3/BuyButton";
import CallButton from "../commonV3/CallButton";
import NeedHelpButton from "../commonV3/NeedHelpButton"

const PRICE_ID = {
    ADULTS: process.env.GATSBY_ADULTS,
    MONTHLY: process.env.GATSBY_MONTHLY,
    ANNUAL: process.env.GATSBY_ANNUAL,
    FREEWATCH: process.env.GATSBY_FREE_WATCH
}

const BuyFixedBottom = ({intl, location, query, layoutType}) => {
    const images = useStaticQuery(graphql`
    query {
      img1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "reloj-durcal-teleasistencia" }
      ) {
        childImageSharp {
          fluid(maxWidth: 61, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

    const appearScrollLimit = layoutType === "buy" ? 400 : 400;

    const [scrollIsTop, setScrollIsTop] = useState(typeof window !== 'undefined' && window.scrollY < appearScrollLimit);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            setScrollIsTop(window.scrollY < appearScrollLimit)
        }, [appearScrollLimit]
    );
    useEffect(() => {
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    if (new URLSearchParams(location.search)?.get("userId") && layoutType !== "buy") return (<div/>);

    const {purchase_method, userId, promotion_code, fpr} = query

    const BuyFixedBottomSectionWrapper = styled.div`
      max-width: 1440px;
      align-self: center;
      margin: auto;
      z-index: 1020;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 12px;
      gap: 12px;
      justify-content: space-between;
      display: ${scrollIsTop? "none": "flex"};

      position: fixed;
      bottom: 0;
      width: 100%;

      /* White */
      background: #FFFFFF;

      /* sombra gris */
      box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.18);
      border-radius: 20px 20px 0px 0px;

      @media (max-width: ${props => props.theme.screen.sm}) {
        gap: unset;
      }
      @media (min-width: ${props => props.theme.screen.xl}) {
        top: 0;
        bottom: unset;  
        margin-top: 46px; 
        border-radius: 0;
        max-width: unset;
        justify-content: unset;
        
        padding: 8px 64px;
        height: 112px;
        box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.2)
      }
    `
    return (
        <BuyFixedBottomSectionWrapper location={location}>
            <WatchImgAndPrice>
                <ImageWrapper>
                    <StyledImage fluid={images.img1.childImageSharp.fluid}/>
                </ImageWrapper>
                <div className="TitleAndPrice">
                    <div className={"Title"}>{intl.formatMessage({ id: "buy_watch_button_header" })}</div>
                </div>
            </WatchImgAndPrice>
            {layoutType !== "buy" && ( <ButtonsDiv layoutType={layoutType}>
                <div className="needhelp">
                  <NeedHelpButton
                    type="secondary"
                    fromhome={true}
                  >
                    <ButtonText>{intl.formatMessage({ id: "needHelp" })}</ButtonText>
                  </NeedHelpButton>
                </div>
                <MoreInfoButton
                    location={location}
                    intl={intl}
                    type="primary"
                    eventType="buy"
                >
                    <ButtonText>{intl.formatMessage({ id: "buyButton" })}</ButtonText>
                </MoreInfoButton>
                <div className="call">
                    <CallButton
                        location={location}
                        intl={intl}
                        type="secondary"
                    >
                        <ButtonText>{intl.formatMessage({ id: "button_call" })}</ButtonText>
                    </CallButton>
                </div>
            </ButtonsDiv>)}
            {layoutType === "buy" && (<ButtonsDiv layoutType={layoutType}>
              <div className="needhelpprimary">
                <NeedHelpButton type="primary">
                  <ButtonText>{intl.formatMessage({ id: "needHelp" })}</ButtonText>
                </NeedHelpButton>
              </div>
              <div className="needhelpsecondary">
                <NeedHelpButton type="secondary">
                  <ButtonText>{intl.formatMessage({ id: "needHelp" })}</ButtonText>
                </NeedHelpButton>
              </div>
                <div className="call">
                  <CallButton
                    location={location}
                    intl={intl}
                    type="secondary"
                  >
                    <ButtonText>{intl.formatMessage({ id: "button_call" })}</ButtonText>
                  </CallButton>
                </div>
            </ButtonsDiv>)}

        </BuyFixedBottomSectionWrapper>

    )
}


const WatchImgAndPrice = styled.div`
    display: ${props => (props.layoutType === "buy" ? "none" : "flex")};
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding: 0px 8px 0px 0px;
    gap: 2px;

    @media (min-width: ${props => props.theme.screen.xl}) {
        flex-grow: 2;
    }

    .TitleAndPrice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 4px;

    @media (min-width: ${props => props.theme.screen.xl}) {
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 2;
      align-items: center;
    }

    .Title {
      width: 86px;
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.bold};
      @media (max-width: ${props => props.theme.screen.sm}) {
        display: none;
      }
      @media (min-width: ${props => props.theme.screen.xl}) {
        width: 100%;
        ${({ theme }) => theme.font_size.large};
      }
    }
  }
`

const Price = styled.div`
  align-items: center;
  text-align: left;
  width: 100%;
  ${({ theme }) => theme.font_size.medium};
  font-family: ${({ theme }) => theme.font.bold};
  display: ${props => (props.layoutType === "buy" ? "none" : "block")};

  @media (min-width: ${props => props.theme.screen.xl}) {
    text-align: right;
    ${({ theme }) => theme.font_size.larger};
  }
`
const ButtonsDiv = styled.div`
  display: flex;
  /*flex-direction: ${props => (props.layoutType === "buy" ? "column" : "row")};*/
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  flex-grow: 1;
  max-width: ${props => (props.layoutType !== "buy" ? "800px" : "800px")};
  .needhelp {display: none}
  .call {width:100%;display: flex}
    .needhelpprimary {width:100%;display: ${props => (props.layoutType !== "buy" ? "none" : "flex")}};
    .needhelpsecondary {display: ${props => (props.layoutType !== "buy" ? "flex" : "none")}};
  
  @media (min-width: ${props => props.theme.screen.xl}) {
    flex-direction: row;
    min-width: fit-content;
      justify-content: right;
    //max-width: 301px;
    gap: 16px;
    .call {display: none;}
    .needhelp {width:100%;display: flex}
      .needhelpprimary {width:100%;display: ${props => (props.layoutType !== "buy" ? "flex" : "none")}};
      .needhelpsecondary {display: ${props => (props.layoutType !== "buy" ? "none" : "flex")}};

  }
`

const PriceAnnual = styled.span`
  width: 100%;
  ${({ theme }) => theme.font_size.small};
  font-family: ${({ theme }) => theme.font.medium};
  @media (min-width: ${props => props.theme.screen.xl}) {
    ${({ theme }) => theme.font_size.regular};
  }
`

const ButtonText = styled.div`
  margin: 0;
  text-align: center;
  ${({theme}) => theme.font_size.xsmall};
  font-family: ${({theme}) => theme.font.bold};

  @media (min-width: ${props => props.theme.screen.xl}) {
    ${({ theme }) => theme.font_size.regular};
  }
`

const ImageWrapper = styled.div`
  width: 40px;

  @media (min-width: ${props => props.theme.screen.xl}) {
    width: 61px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

const StyledImage = styled(Img)`
  display: block;
`

export default withQueryParams(
    {
        purchase_method: StringParam,
        userId: StringParam,
        promotion_code: StringParam,
        fpr: StringParam
    },
    injectIntl(BuyFixedBottom),
)
